import React, { useState } from "react";
import { useTimer } from "react-timer-hook";

function MyDaysTimer({ expiryTimestamp }) {
  const { seconds, minutes, hours, days } = useTimer({ expiryTimestamp });

  return (
    <strong className="offer-time-end">
      {Math.floor(minutes / 10)}
      {minutes % 10}:{Math.floor(seconds / 10)}
      {seconds % 10}
    </strong>
  );
}

export const Footertimer = (props) => {
  const [showdiscount, setshowdiscount] = useState(true);
  function addDays(theDate, days) {
    return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  }

  var newtime = addDays(new Date(), (1 / 24 / 60) * props.limit);
  return <MyDaysTimer expiryTimestamp={newtime} />;
};
