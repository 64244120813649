import React, { useEffect, useState } from "react";
import { Footertimer } from "./Footertimer";

export const Reserveseat = () => {
  const [showdiscount, setdiscount] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setdiscount(false);
    }, 8 * 60000);
  }, []);
  return (
    <div className="price-counter">
      <div className="co-month-text">
        Register before{" "}
        <strong> 21st June to unlock bonuses worth ₹11,000</strong>
      </div>
      <a href="https://rzp.io/l/ROBOPACK-299" target="_blank">
        <div className="counter-register-btn">
          Register Now for ₹299 <span className="active">₹899</span>
          {/* Register Now for <span>{showdiscount === true ? "₹299" : "₹899"}</span> */}
          {/* <span className="active">
          {showdiscount === true ? "₹899" : "₹299"}
        </span> */}
        </div>
      </a>
      <div className="counter-offer-ends-text">
        This Offer ends in : <Footertimer limit={8} />
      </div>
    </div>
  );
};
