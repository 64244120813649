import React, { useEffect, useState } from "react";
import "./Counter.css";
import Accordion from "./Accordion";
import robopackText from "../assets/images/robopack-text.png";
import CountUp from "react-countup";
import { useTimer } from "react-timer-hook";
import { Reserveseat } from "./Reserveseat";

function MyDaysTimer({ expiryTimestamp }) {
  const { seconds, minutes, hours, days } = useTimer({ expiryTimestamp });

  return (
    <div className="counter-days">
      <div className="coun-days-hours">
        <strong>DAYS</strong>
        <div className="counter-number">
          <span>{Math.floor(days / 10)}</span>
          <span>{days % 10}</span>
        </div>
      </div>
      <div className="coun-days-hours">
        <strong>HOURS</strong>
        <div className="counter-number">
          <span>{Math.floor(hours / 10)}</span>
          <span>{hours % 10}</span>
        </div>
      </div>
      <div className="coun-days-hours">
        <strong>MINUTES</strong>
        <div className="counter-number">
          <span>{Math.floor(minutes / 10)}</span>
          <span>{minutes % 10}</span>
        </div>
      </div>
    </div>
  );
}
const Counter = () => {
  function addDays(theDate, days) {
    return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  }

  var newDate = addDays(new Date(), 12);
  const time = newDate;
  time.setSeconds(time.getSeconds());
  return (
    <div className="counter-section">
      <img src={robopackText} className="robopack-text" />
      <div className="counter_container">
        <div className="hero-seeking-text">
          Are you seeking an affordable yet comprehensive solution to
          <strong className="seeking-red-color">elevate your expertise?</strong>
          <br></br>
          Look no further! ROBO Pack offers an industrial-grade package at
          <strong className="seeking-red-yellow">just Rs. 299,</strong>
          providing you with unmatched value and insights.
        </div>
        <MyDaysTimer expiryTimestamp={time} />
        <div className="counter-from">
          <div className="counter-from-content">
            <div className="counter-from-text">
              <div className="co-from-text text-counter1">
                <p>New Batch Starting</p>
                <div className="starting-text">
                  JUNE 22<sub>nd</sub>, 2024
                </div>
              </div>
            </div>
            <div className="counter-from-text">
              <div className="co-from-text text-counter2">
                <p>Knowledge pack of</p>
                <div className="starting-text">
                  <CountUp end={360} duration={5} />+ <strong>Minutes</strong>
                </div>
              </div>
            </div>
            <div className="counter-from-text">
              <div className="co-from-text text-counter3">
                <p>More than</p>
                <div className="starting-text">
                  <CountUp end={6500} duration={5} />+ <strong>Learners</strong>
                </div>
              </div>
            </div>
            <div className="counter-from-text">
              <div className="co-from-text text-counter3">
                <p>Learn From</p>
                <div className="starting-text">Industry Experts</div>
              </div>
            </div>
            <div className="counter-from-text">
              <div className="co-from-text text-counter4">
                <p>Gain</p>
                <div className="starting-text">Practical skills</div>
              </div>
            </div>
          </div>
        </div>
        <Reserveseat />
      </div>
    </div>
  );
};

export default Counter;
