import React from "react";
import "./InternshipCarousel.css";

import fanuc from "../assets/images/fanuc-logo.png";
import toyoto from "../assets/images/toyota-logo.png";
import Daimler from "../assets/images/daimler-logo.png";
import Force from "../assets/images/force-logo.png";
import Tailnode from "../assets/images/tailnode-logo.png";
import RMMoto from "../assets/images/rm-moto-logo.png";

import Marquee from "react-fast-marquee";

const InternshipCarousel = () => {
  return (
    <div className="internship-carousel-section">
      <div className="internship-title">
        <span>Internship</span> Opportunities at:
      </div>
      <div className="internship-carousel-content-bar">
        <div className="internship-carousel-logos">
          <Marquee>
            <div className="internship-logo">
              <img src={fanuc} />
            </div>
            <div className="internship-logo">
              <img src={toyoto} />
            </div>
            <div className="internship-logo">
              <img src={Daimler} />
            </div>
            <div className="internship-logo">
              <img src={Force} />
            </div>
            <div className="internship-logo">
              <img src={Tailnode} />
            </div>
            <div className="internship-logo">
              <img src={RMMoto} />
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default InternshipCarousel;
