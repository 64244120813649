import React from "react";
import "./WhatWillYou.css";

import lineYellow from "../assets/images/line-yellow.svg";

const WhatWillYou = () => {
  return (
    <div className="WhatWillYou__container" id="learnid">
      <div className="meet-your-title">
        What Will you <span>learn?</span>
      </div>
      <div className="what-will-content">
        <div className="what-will-text-box">
          <div className="what-will-text">
            <div className="will-title">Day 1</div>
            <ul>
              <li>Deep Artificial Processing</li>
              <li>Introduction to Python Programming</li>
              <li>Understanding Machine Learning Concepts</li>
              <li>Hands-On Practical Exercises</li>
            </ul>
          </div>
        </div>
        <div className="what-will-text-box">
          <div className="what-will-text">
            <div className="will-title">Day 2</div>
            <ul>
              <li>Mechanical & Mathematics</li>
              <li>Exploring Matrices, Vectors, & Transformations</li>
              <li>Diving into Electronics & IoT</li>
              <li>Modeling Electronic Components</li>
              <li>Simulating IoT with EasyEDA</li>
            </ul>
          </div>
        </div>
        <div className="what-will-text-box c">
          <div className="what-will-text">
            <div className="will-title">Bonus Day</div>
            <div className="industrial-text">
              <strong>RoboAI</strong> : Industrial Training Program
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWillYou;
