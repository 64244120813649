import React from "react";
import "./StillTrust.css";
import { Reserveseat } from "./Reserveseat";

import TrustImg1 from "../assets/images/TrustImg1.jpeg";
import TrustImg2 from "../assets/images/TrustImg2.jpeg";
import TrustImg3 from "../assets/images/TrustImg3.jpeg";
import ananya from "../assets/images/ananya.png";
import TrustImg4 from "../assets/images/TrustImg4.jpeg";
import Trust4 from "../assets/images/Trust4.png";
import TrustImg5 from "../assets/images/TrustImg5.jpeg";

import ParitoshFinal from "../assets/videos/Paritosh-Final.mp4";
import ParthBiyani from "../assets/videos/ParthBiyani.mp4";
import RajAryan from "../assets/videos/RajAryan.mp4";
import AkashRai from "../assets/videos/AkashRai.mp4";
import Abishekks from "../assets/videos/Abishekks.mp4";
import PillaiKarun from "../assets/videos/PillaiKarun.mp4";
import UdayanYadav from "../assets/videos/UdayanYadav.mp4";
import VedNarsekar from "../assets/videos/VedNarsekar.mp4";
import VideoTestimony from "../assets/videos/VideoTestimony.mp4";
import VishalKumar from "../assets/videos/VishalKumar.mp4";
import TrustCarousel from "./TrustCarousel";

const videos = [
  ParitoshFinal,
  ParthBiyani,
  RajAryan,
  AkashRai,
  Abishekks,
  PillaiKarun,
  UdayanYadav,
  VedNarsekar,
  VideoTestimony,
  VishalKumar,
];

export const StillTrust = () => {
  return (
    <div className="before-you-ask-section">
      <div className="before-you-ask_container">
        <div class="before-you-ask-title">
          <h2>
            Still Don’t Trust <span>us?</span>
          </h2>
          <p>
            Check out what our previous learners have to say about the workshop
          </p>
        </div>
        <div className="TrustCarousel-mobile">
          <TrustCarousel />
        </div>
        <div className="trust-us-vidio">
          <div className="trust-bar">
            <div className="trust-vidio-tex">
              <div className="trust-vidio-img">
                <img src={TrustImg1} className="" width={500} height={490} />
              </div>
              <div className="trust-vidio-img">
                <img src={TrustImg2} className="" width={500} height={490} />
              </div>
            </div>
            <div className="trust-vidio-tex">
              <div className="trust-vidio-img">
                <img src={TrustImg3} className="" />
              </div>
            </div>
            <div className="trust-vidio-tex">
              <div className="trust-vidio-img">
                <img src={ananya} className="" />
              </div>
              <div className="trust-vidio-img">
                <img src={TrustImg4} className="" />
              </div>
            </div>
            <div className="trust-vidio-tex">
              <div className="trust-vidio-img">
                <img src={Trust4} className="" />
              </div>
              <div className="trust-vidio-img">
                <img src={TrustImg5} className="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
