import React from "react";
import "./Footer.css";
import robotc from "../assets/TERMS AND CONDITIONS ROBOPACK.pdf";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faTicket } from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faLinkedin,
  faInstagram,
  faInstagramSquare,
  faFacebook,
  faFacebookSquare,
  faYoutubeSquare,
} from "@fortawesome/free-brands-svg-icons";

import footerLogo from "../assets/images/footer-logo.svg";
import FooterSearchIcon from "../assets/images/footer-search-icon.png";
import InstagramIcon from "../assets/images/instagram-icon.png";
import LinkedinIcon from "../assets/images/linkedin-icon.png";
import FacebookIcon from "../assets/images/facebook-icon.png";
import YoutubeIcon from "../assets/images/youtube-icon.png";
import TwitterIcon from "../assets/images/twitter-icon.png";

const Footer = () => {
  return (
    <div className="footer__container" id="footerid">
      <div className="footer">
        <div className="footer--image-block">
          <a href="http://myequation.in/" target="_blank">
            <img src={footerLogo} className="footer__logo-image" />
          </a>
          <p className="foot--txt">
            India's only startup to provide an ecosystem for core engineers
          </p>
          <div></div>
        </div>
        <div className="footer--middle">
          <div className="footer__contact-container">
            <div className="footer__contact-card">
              <p>Gunesh - 8806806479</p>
              <p>(SMO Head)</p>
            </div>
            <div className="footer__contact-card">
              <p>Kaustuv - 9337837362</p>
              <p>(OutReach)</p>
            </div>
            {/* <div className="footer-search">
              <input type="text" placeholder="FILL YOUR DETAILS" />
              <span className="footer-search-icon">
                <img src={FooterSearchIcon} className="search-icon" />
              </span>
            </div> */}
          </div>

          <div
            className="footer-Follow-us"
            style={{
              display: "flex",
              // flexDirection: "column",
              gap: "10px",
              alignItems: "center",
              flexWrap: "wrap",
              marginBottom: "40px",
            }}
          >
            <p
              className="footer-follow-text"
              style={{
                textAlign: "center",
              }}
            >
              Follow us on !
            </p>
            <div className="footer__brand--container">
              <div className="footer-social-icon">
                <a href="#">
                  <img src={InstagramIcon} className="social-icon" />
                </a>
                <a href="#">
                  <img src={LinkedinIcon} className="social-icon" />
                </a>
                <a href="#">
                  <img src={FacebookIcon} className="social-icon" />
                </a>
                <a href="#">
                  <img src={YoutubeIcon} className="social-icon" />
                </a>
                <a href="#">
                  <img src={TwitterIcon} className="social-icon" />
                </a>
              </div>
              <p
                style={{
                  fontSize: "18px",
                }}
              >
                /{" "}
                <a
                  className="my-equation-text"
                  href="http://myequation.in/"
                  target="_blank"
                >
                  {" "}
                  My Equation
                </a>
              </p>
            </div>
          </div>
        </div>
        <p

  className="footer-copyright-text"
  style={{
    color: "#fff",
    textAlign: "center",
  }}
>
  By accessing and using this website, you agree to abide by our <a href={robotc}><b> <u>Terms and Conditions</u> </b></a>. Please       review them carefully before proceeding
</p>
        <p
          className="footer-copyright-text"
          style={{
            textAlign: "center",
          }}
        >
          MyEquationTM is a registered trademark under Tech Analogy Pvt Ltd. All
          rights reserved | ©
        </p>
      </div>
    </div>
  );
};

export default Footer;
