import React, { useRef, useState } from "react";
import "./Stories.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import "swiper/css/navigation";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import DaubleQuote from "../assets/images/dauble-quote.png";

import AnubhavKumar from "../assets/images/anubhav-kumar.png";
import SahilBhoi from "../assets/images/sahil-bhoi.png";
import SnehilSharma from "../assets/images/snehil-sharma.png";
import KaranPatel from "../assets/images/karan-patel.png";
import AryanDineshkumarTiwari from "../assets/images/aryan-dineshkumar-tiwari.png";
import AlimAhmed from "../assets/images/alim-ahmed.png";

import str1050 from "../assets/images/stories-01.png";
import str1051 from "../assets/images/stories-02.png";
import str1052 from "../assets/images/stories-03.png";
import str1053 from "../assets/images/stories-04.png";
import str1054 from "../assets/images/stories-05.png";
import str1055 from "../assets/images/stories-06.png";
import str1056 from "../assets/images/stories-07.png";

// const stories = [story1, story2, story3, story4, story5];
const stories = [str1050, str1051, str1052, str1053, str1054, str1055, str1056];

const responsive = {
  a: {
    breakpoint: { max: 4000, min: 1597 },
    items: 1,
  },
  b: {
    breakpoint: { max: 1597, min: 1197 },
    items: 1,
  },
  superLargeDesktop: {
    breakpoint: { max: 1197, min: 1023 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 1023, min: 767 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 767, min: 575 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
  },
};

const Stories = () => {
  const [ind, setind] = useState(0);
  const imgurl = [
    AnubhavKumar,
    SahilBhoi,
    SnehilSharma,
    KaranPatel,
    AryanDineshkumarTiwari,
    AlimAhmed,
  ];
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<span class="' +
        className +
        '">' +
        '<div style=`display:"flex"`><img src="' +
        imgurl[index] +
        '"/></div>' +
        "</span>"
      );
    },
  };
  const swiperRefLocal = useRef();

  const handleMouseEnter = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseLeave = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.start();
  };
  return (
    <div className="stories__container">
      <div className="stories">
        <div className="stories_title-card">
          <h2 className="stories_text_reg">
            Hear our Previous Successful <span>!</span>
          </h2>
        </div>
        <div className="hear-previou-section">
          <div className="hear-previou-bottom-bar">
            <div
              className="previous-content"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Swiper
                ref={swiperRefLocal}
                autoplay={{
                  delay: 2000,
                }}
                pagination={pagination}
                loop={true}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="prev-text-carousel">
                    <img src={DaubleQuote} className="dauble-quote-icon" />
                    <h2>Anubhav Kumar</h2>
                    <p>
                      The three-day workshop was super helpful and fun. Each day
                      focused on different parts of building robots. The first
                      day was all about the mechanical stuff, like how robots
                      are put together. The second day was about electronics,
                      which is like the brains and nerves of the robot. And the
                      third day was all about AI and machine learning, which is
                      how robots can learn and make decisions on their own. The
                      workshop was well-organized, and the teachers were patient
                      and clear in explaining everything. They even extended the
                      deadlines for assignments, which was really nice. I'm
                      looking forward to attending more training programs like
                      this with My Equation!
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="prev-text-carousel">
                    <img src={DaubleQuote} className="dauble-quote-icon" />
                    <h2>Sahil Bhoi</h2>
                    <p>
                      "The Robo Pack workshop was really cool! It lasted three
                      days, and each day focused on something different. The
                      first day was all about making the physical parts of
                      robots, like how to design them using SolidWorks. It was
                      neat to learn how spot robot design works. The second day
                      was about electronics. We learned about circuits and how
                      to put together different parts to make our robots work.
                      The third day was the best! We learned about AI/ML, which
                      is like teaching robots how to learn and think. The
                      instructors were awesome and made learning fun. I totally
                      recommend this workshop to anyone who wants to learn about
                      robots!"
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="prev-text-carousel">
                    <img src={DaubleQuote} className="dauble-quote-icon" />
                    <h2>Snehil Sharma</h2>
                    <p>
                      The workshop was super helpful for learning about robots
                      and AI. We spent the first day learning about how robots
                      work and designing them. Then, the next day, we focused on
                      electronics and how they make robots tick. Finally, we got
                      into the cool stuff: artificial intelligence and machine
                      learning on the last day. We also had chances to ask
                      questions and get advice about careers. I learned a ton
                      and can't wait to use it in the future!
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="prev-text-carousel">
                    <img src={DaubleQuote} className="dauble-quote-icon" />
                    <h2>Karan Patel</h2>
                    <p>
                      The workshop was really insightful! It gave us a good
                      overview of robot development in just three days. We
                      learned about the basics and got a glimpse of the more
                      advanced stuff using different platforms. The sessions
                      were well-planned and easy to follow. The materials were
                      organized, which made it easy to review. They even
                      extended the assignment deadlines, so it didn't clash with
                      our university work. The teachers were awesome, patient,
                      and really made sure we understood everything. They
                      answered all our questions and made learning fun. Overall,
                      it was a great learning experience!
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="prev-text-carousel">
                    <img src={DaubleQuote} className="dauble-quote-icon" />
                    <h2>Aryan Dineshkumar Tiwari</h2>
                    <p>
                      I want to share with you about Robo pack workshop. It's
                      perfect for anyone who wants to learn about making robots.
                      The workshop lasts for three days, and it's really
                      well-organized. The teachers are super smart and make
                      learning fun. In the workshop, you'll learn all sorts of
                      things about robots. They start with the basics of
                      electronics and programming, and then they move on to more
                      advanced stuff like machine learning and artificial
                      intelligence.They let you do hands-on projects, which
                      really helps you understand everything better. I had a
                      blast at the Robo pack workshop. I learned heaps, met some
                      cool people, and had a great time. If you're interested in
                      making robots, I totally recommend this workshop!
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="prev-text-carousel">
                    <img src={DaubleQuote} className="dauble-quote-icon" />
                    <h2>Alim Ahmed</h2>
                    <p>
                      Robo Pack was a transformative learning experience for me.
                      As i got to know intricacies of each subject and how they
                      connect with each other to build a system. The mentors
                      were very patient in explaining and clearing all the
                      doubts.the assignment given by team was very valuable as i
                      got to think out of the box to build the projects.
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stories;
