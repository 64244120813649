import React from "react";
import "./BeforeYouAsk.css";
import { Reserveseat } from "./Reserveseat";

import AskImg from "../assets/images/ask-img.png";

export const BeforeYouAsk = () => {
  return (
    <div className="before-you-ask-section">
      <div className="before-you-ask_container">
        <div class="before-you-ask-title">
          <h2>
            Before you <span>ask</span>
          </h2>
          <p>Yes! You will be certified for this workshop!</p>
        </div>
        <div className="before-you-ask-text">
          <div className="before-you-ask-left">
            <img src={AskImg} className="fe-img-icon" />
          </div>
          <div className="before-you-ask-right">
            <div className="validate-text">
              <h2>Validate your learning</h2>
              <p>
                Earn an Official Certificate Signed by Your Mentor, Certifying
                Your Course Completion and Knowledge Acquisition.
              </p>
            </div>
            <div className="validate-text">
              <h2>Showcase Your Achievement</h2>
              <p>
                Display Your My Equation Robopack Certificate on Your Resume or
                LinkedIn Profile. Impress Prospective Employers with Tangible
                Proof of Your Expertise.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
