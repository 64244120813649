import React from "react";
import "./CheckOut.css";
import { Reserveseat } from "./Reserveseat";

import RobopackMontage from "../assets/videos/RobopackMontage.mp4";
import AskVidio from "../assets/images/ask-vidio.png";

const videos = [RobopackMontage];

export const CheckOut = () => {
  return (
    <div className="CheckOut_container">
      <div className="future-reserveseat">
        <Reserveseat />
      </div>
      <div className="check-out-section">
        <div class="check-out-title">
          <h2>
            Check out our previous <span>Robopack</span>
          </h2>
          <p>Yes! You will be certified for this workshop!</p>
        </div>
        <div className="ask-vidio-box">
          <div className="ask-vidio">
            <video
              width="100%"
              height="360"
              controls
              disablePictureInPicture
              controlslist="nodownload noplaybackrate"
            >
              <source src={RobopackMontage} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};
