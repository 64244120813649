import React from "react";
import "./Mentors.css";
import ReactShowMoreText from "react-show-more-text";
import KeivalyaP from "../assets/images/keivalya-p.png";
import benzaleel from "../assets/images/benzaleel.svg";
import ShashankShekar from "../assets/images/shashank-shekar.png";
import aaksshImg from "../assets/images/akash-img.png";
import JagdeepSingh from "../assets/images/jagdeep-singh.png";
import linkedin from "../assets/images/linkedin.png";

const MentorCard = ({ name, text, img, icon, linkedinusername }) => {
  return (
    <div className="mentor-block">
      <div className="mentor__card">
        <img src={img} className="mentor__card--image" />
        <div className="mentor__card--content">
          <p className="mentor__card--name">{name}</p>
          <p className="mentor__card--desc">{text}</p>
          <div className="linkedin-icon">
            <a
              href={`https://www.linkedin.com/in/${linkedinusername}`}
              target="_blank"
            >
              <img src={icon} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const Mentors = () => {
  return (
    <div className="mentor__container" id="mentor-id">
      <div className="mentor">
        <div style={{ width: "100%" }}>
          <div className="meet-title-block">
            <div className="meet-your-title">
              Meet your <span>Mentors</span>
            </div>
          </div>
          <div className="mentor__card-container">
            <MentorCard
              name={"Keivalya P."}
              text={"CTO & Co-founder QuickBot Tech"}
              img={KeivalyaP}
              icon={linkedin}
              linkedinusername={`keivalya`}
            />
            <MentorCard
              name={"Shashank Shekar"}
              text={"Sr Design and development Engineer, Accio Robotics"}
              img={ShashankShekar}
              icon={linkedin}
              linkedinusername={`shashank-shekar-2215471a1`}
            />
            <MentorCard
              name={"Jagdeep Singh"}
              text={"Sr. Engineer, KUKA"}
              img={JagdeepSingh}
              icon={linkedin}
              linkedinusername={`jagdeep-singh112`}
            />
          </div>
          <ReactShowMoreText
            more={<div className="mentor-show-more">See More</div>}
            less={<div className="mentor-show-more Less-more">See Less</div>}
            width={"0"}
          >
            <div className="mentor__card-container less__card-container">
              <MentorCard
                name={"Akash"}
                text={"Firmware Engineer, Apple"}
                img={aaksshImg}
                icon={linkedin}
                linkedinusername={`akash-kapashia-33820295`}
              />

              <MentorCard
                name={"Bezaleel"}
                text={
                  "Robotics Consultant@Prag Robotics,Ed - Specialist@Enlight Wisdom"
                }
                img={benzaleel}
                icon={linkedin}
                linkedinusername={`bezaleel-beslee`}
              />
            </div>
          </ReactShowMoreText>
        </div>
      </div>
    </div>
  );
};

export default Mentors;
