import React from "react";
import "./HeroSection.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCoffee,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

import herotext from "../assets/images/herotext.svg";
import heroImage from "../assets/images/heroImage.png";
import Navbar from "./Navbar";

const HeroSection = () => {
  return (
    <div className="herosection__container">
      <Navbar />
      <div className="hero">
        <div className="hero-main-content">
          <div className="hero-banner-text">
            <div className="hero-left-bar">
              <div className="banner-left-img">
                <img src={herotext} className="herosection__image-text" />
              </div>
              <div className="banner-content">
                <h2>Welcome to 3-Day</h2>
                <p className="banner-text">
                  Interactive Workshop <span>on</span>
                  <br></br> Robotics & AI
                </p>
              </div>
              <div className="mobile-banner-img">
                <img src={heroImage} />
              </div>
              <div className="herosection__bottom">
                <div className="herosection__btns">
                  <a href="https://rzp.io/l/ROBOPACK-299" className="hero__enroll-btn">
                    Apply Now
                  </a>
                  <a href="#learnid" className="hero__enroll-btn learn-more">
                    Learn more
                  </a>
                </div>
              </div>
            </div>
            <div className="hero-right-bar">
              <img src={heroImage} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
