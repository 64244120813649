import React from "react";
import "./Future.css";
import { Reserveseat } from "./Reserveseat";

import IndustryIcon from "../assets/images/industry-icon.png";
import SessionIcon from "../assets/images/session-icon.png";
import ExpertIcon from "../assets/images/expert-icon.png";

export const Future = () => {
  return (
    <div className="future-section">
      <div className="future_container">
        <div className="future-reserveseat">
          <Reserveseat />
        </div>
        <div className="future-content" id="futureid">
          <div className="future-title">
            <span>Feature</span> of Robopack
          </div>
          <div className="feature-text-section">
            <div className="feature_card-container">
              <div className="feature_card-block">
                <div className="feature-mentor-text">
                  <img src={IndustryIcon} className="fe-img-icon" />
                  <p>Industry Techniques Taught by Experts</p>
                </div>
              </div>
              <div className="feature_card-block">
                <div className="feature-mentor-text">
                  <img src={SessionIcon} className="fe-img-icon" />
                  <p>Session will be taken by industry experts</p>
                </div>
              </div>
              <div className="feature_card-block">
                <div className="feature-mentor-text">
                  <img src={ExpertIcon} className="fe-img-icon" />
                  <p>Live 1:1 Expert Session</p>
                </div>
              </div>
            </div>
            <div className="feature-ul-bar">
              <ul>
                <li>Receive a Certificate of Completion</li>
                <li>Flexible Online Sessions for Your Convenience</li>
                <li>Hands-On Practical Skills Development</li>
              </ul>
              <ul>
                <li>Expert Guidance Every Step of the Way</li>
                <li>Engage with a Thriving Community</li>
                <li>All at an Affordable Price Point</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
