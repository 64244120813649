import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faClose,
  faCoffee,
  faL,
  faSlash,
} from "@fortawesome/free-solid-svg-icons";

import Logo from "../assets/images/nav_logo.png";
import marqueeicon from "../assets/images/marquee-icon.png";

import "./Navbar.css";
import Marquee from "react-fast-marquee";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  return (
    <div className="navbar__container">
      <div className="navbar">
        <div className="header-left">
          <div className="navbar__logo">
            <img src={Logo} alt="Logo" className="navbar__logo--image" />
          </div>
          <div className="navbar__links">
            <a href="#mentor-id" className="web--link">
              Mentor
            </a>
            <a href="#futureid" className="web--link">
              Why Enrol?
            </a>
            {/* <a href="#" className="web--link">
              About us
            </a> */}
          </div>
        </div>
        <div className="header-btns">
          <a
            href="#"
            target="blank"
            className="web--link-btn queries-btn"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            Queries?
          </a>
          <a
            href="#"
            target="blank"
            className="web--link-btn call-btn"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            Call : +91 88068 06479
          </a>
        </div>

        <div className="navbar__ham" onClick={() => setIsMobileMenuOpen(true)}>
          <FontAwesomeIcon
            icon={faBars}
            color="#fff"
            className="navbar__icon"
          />
        </div>

        {isMobileMenuOpen && (
          <div className="modal-overlay">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <div className="navbar__logo">
                <img
                  src={Logo}
                  alt="Logo"
                  style={{ width: "160px", marginTop: "5px" }}
                />
              </div>
              <FontAwesomeIcon
                size="50px"
                icon={faClose}
                onClick={() => setIsMobileMenuOpen(false)}
                className="close-icon"
              />
            </div>
            <div className="modal">
              <a href="#mentor-id" className="web--link">
                Mentor
              </a>
              <a href="#futureid" className="web--link">
                Why Enrol?
              </a>
              {/* <a href="#" className="web--link">
              About us
            </a> */}
            </div>
          </div>
        )}
      </div>
      <div className="header-marquee-section">
        <Marquee>
          <div className="header-marquee">
            <img src={marqueeicon} alt="Logo" className="marquee-icon" />
            Speed Run offer : Price resets to Rs ₹899 when the timer below hits
            0.
          </div>
          <div className="header-marquee">
            <img src={marqueeicon} alt="Logo" className="marquee-icon" />
            Speed Run offer : Price resets to Rs ₹899 when the timer below hits
            0.
          </div>
          <div className="header-marquee">
            <img src={marqueeicon} alt="Logo" className="marquee-icon" />
            Speed Run offer : Price resets to Rs ₹899 when the timer below hits
            0.
          </div>
        </Marquee>
      </div>
    </div>
  );
};

export default Navbar;
