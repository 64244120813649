import React, { Fragment, useEffect, useState } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import Mentors from "./components/Mentors";
import Enroll from "./components/Enroll";
import Equation from "./components/Equation";
import Timeline from "./components/Timeline";
import Certificates from "./components/Certificates";
import Stories from "./components/Stories";
import Mentees from "./components/Mentees";
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";
import Counter from "./components/Counter";
import WhoCanApply from "./components/WhoCanApply";
import WhatWillYou from "./components/WhatWillYou";
import InternshipCarousel from "./components/InternshipCarousel";
import { Future } from "./components/Future";
import { BeforeYouAsk } from "./components/BeforeYouAsk";
import { CheckOut } from "./components/CheckOut";
import { StillTrust } from "./components/StillTrust";
import RightIcon from "./assets/images/right-icon.png";

import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import { SnackbarContent } from "@mui/material";
const App = () => {
  const [open, setOpen] = useState(false);
  const [show, setshow] = useState(true);
  const handleClick = () => {
    setOpen(true);
  };
  useEffect(() => {
    setTimeout(() => {
      if (show === true) {
        handleClick();
        setshow(false);
      } else {
        setshow(false);
      }
    });
  }, [show]);
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        X{/* <CloseIcon fontSize="small" /> */}
      </IconButton>
    </Fragment>
  );
  const dataarray = [
    {
      name: "Raj",
      place: "Rajasthan",
      time: "4 hours",
    },
    {
      name: "Manoj",
      place: "Rajashthan",
      time: "3 hours",
    },
    {
      name: "Rushikesh",
      place: "Gujarat",
      time: "5 minutes",
    },
    {
      name: "Prayag",
      place: "Madhya Pradesh",
      time: "4 hours",
    },
  ];
  const [loadpoup, setloadpopup] = useState(3);
  useEffect(() => {
    let i = 0;
    setInterval(() => {
      setloadpopup(i);
      setshow(true);
      if (i === dataarray.length - 1) {
        i = 0;
      } else {
        i++;
      }
    }, 10000);
  }, []);
  console.log(Math.ceil(loadpoup));
  return (
    <div
      style={{
        overflow: "hidden",
      }}
    >
      <Navbar />
      <HeroSection />
      <Counter />
      <WhoCanApply />
      <Mentors />
      <WhatWillYou />
      <InternshipCarousel />
      <Future />
      <Stories />
      <BeforeYouAsk />
      <CheckOut />
      <Enroll />
      <StillTrust />
      <Mentees />
      <Footer />
      {/* <Button onClick={handleClick}>Open Snackbar</Button> */}
      <Snackbar
        open={open}
        autoHideDuration={8000}
        onClose={handleClose}
        message="Test"
        action={action}
      >
        <SnackbarContent
          sx={{ backgroundColor: "#bb4420", color: "white" }}
          message={
            <div className="load-popup-main">
              <div className="right-icon">
                <img src={RightIcon} className="right-icon1" />
              </div>
              {/* {dataarray.map((i) => ( */}
              <div className="load-popup-text">
                <h2>
                  {dataarray[loadpoup].name} from {dataarray[loadpoup].place}
                </h2>
                <p>registered for the Robotics & AI</p>
                <span>{dataarray[loadpoup].time} ago</span>
              </div>
              {/* ))} */}
            </div>
          }
          action={action}
        />
      </Snackbar>
    </div>
  );
};

export default App;
