import React from "react";
import "./Mentees.css";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Abishekks from "../assets/videos/Abishekks.mp4";
import ParthBiyani from "../assets/videos/ParthBiyani.mp4";
import PillaiKarun from "../assets/videos/PillaiKarun.mp4";
import VideoTestimony from "../assets/videos/VideoTestimony.mp4";
// import ParitoshFinal from "../assets/videos/ParitoshFinal.mp4";
// import AkashRai from "../assets/videos/AkashRai.mp4";
// import RajAryan from "../assets/videos/RajAryan.mp4";
// import UdayanYadav from "../assets/videos/UdayanYadav.mp4";
// import VedNarsekar from "../assets/videos/VedNarsekar.mp4";
// import VishalKumar from "../assets/videos/VishalKumar.mp4";

const videos = [
  Abishekks,
  ParthBiyani,
  PillaiKarun,
  VideoTestimony,
  // ParitoshFinal,
  // AkashRai,
  // RajAryan,
  // UdayanYadav,
  // VedNarsekar,
  // VishalKumar,
];

const responsive = {
  a: {
    breakpoint: { max: 4000, min: 1597 },
    items: 4,
  },
  b: {
    breakpoint: { max: 1597, min: 1197 },
    items: 4,
  },
  superLargeDesktop: {
    breakpoint: { max: 1197, min: 1023 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1023, min: 767 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 767, min: 575 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
  },
};

const Mentees = () => {
  return (
    <div className="mentees__container">
      <div className="mentees">
        <div className="mentees__title-card">
          <p className="mentees__title--reg">
            Also there are few you must listen too
          </p>
        </div>
        <div className="previous_carousel">
          <Carousel
            responsive={responsive}
            // centerMode={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            itemClass="slider__item"
          >
            {videos.map((item, index) => (
              <video
                width="100%"
                height="360"
                controls
                disablePictureInPicture
                controlslist="nodownload noplaybackrate"
              >
                <source src={item} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Mentees;
