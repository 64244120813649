import React from "react";
import "./Enroll.css";

import DiscoverImg from "../assets/images/discover-img.png";



const Enroll = () => {
  return (
    <div className="discover-section">
    <div className="discover_container">
      <div className="discover-content-bar">
       <div className="discover-title">
        <h2>Discover your<b>ROI?</b></h2>
        <p>Its definitely more worth what you will invest in for! And that’s a Guarantee :)</p>
       </div>
       <div className="discover-text-bar">
         <div className="discover-left-img">
         <img src={DiscoverImg} className="discover-img" />
         </div>
         <div className="discover-ul-text">
              <div className="check-apply-text">
                <label className="apply-text">
                Acquire in-demand robotics and AI skills for career advancement.
                  <input type="checkbox" />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="check-apply-text">
                <label className="apply-text">
                Gain specialized knowledge, leading to better opportunities and pay.
                  <input type="checkbox" />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="check-apply-text">
                <label className="apply-text">
                Network with experts and peers for valuable relationships.
                  <input type="checkbox" />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="check-apply-text">
                <label className="apply-text">
                Command better salaries with sought-after expertise.
                  <input type="checkbox" />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="check-apply-text">
                <label className="apply-text">
                Master complex concepts and contribute to innovation.
                  <input type="checkbox" />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="check-apply-text">
                <label className="apply-text">
                Stay updated with ongoing resources for continual learning.
                  <input type="checkbox" />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
         </div>
       </div>
    </div>
    </div>
  );
};

export default Enroll;
