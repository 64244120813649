import React from "react";
import "./WhoCanApply.css";
import Accordion from "./Accordion";

import WhoCanApplyImg from "../assets/images/who-can-apply-img.png";

const WhoCanApply = () => {
  return (
    <div className="whocanapply-section">
      <div className="whocanapply-content-bar">
        <div className="text-who-can-apply">
          <h2>
            Who can <b>Apply?</b>
          </h2>
          <p>
            If any of the following reasons resonate with you, then you're the
            perfect candidate for our workshop!
          </p>
          <div className="who-can-apply-bottom-bar">
            <div className="who-can-apply-bottom-left">
              <div className="check-apply-text">
                <label className="apply-text">
                  Graduates from any field
                  <input type="checkbox" />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="check-apply-text">
                <label className="apply-text">
                  Curious beginners in robotics and AI
                  <input type="checkbox" />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="check-apply-text">
                <label className="apply-text">
                  Enthusiasts eager to expand knowledge
                  <input type="checkbox" />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="check-apply-text">
                <label className="apply-text">
                  Students interested in STEM fields
                  <input type="checkbox" />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="check-apply-text">
                <label className="apply-text">
                  Hobbyists exploring new technologies
                  <input type="checkbox" />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="check-apply-text">
                <label className="apply-text">
                  Professionals aiming to enhance skills
                  <input type="checkbox" />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="check-apply-text">
                <label className="apply-text">
                  Innovation and learning enthusiasts!
                  <input type="checkbox" />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="who-can-apply-bottom-right">
              <img src={WhoCanApplyImg} className="herosection__image-text" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoCanApply;
